import React, { useState, useEffect } from "react";
import { Form, Field } from 'react-final-form';
import { TextField, Select } from 'final-form-material-ui';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';

export default function TechGridFormDialog({ close, submit, context }) {
  const { initialValues = null, isChild = false } = context || {};
  const [closing, setClosing] = useState(false);

  const validate = values => {
    const requiredFields = ['name', 'importance', 'description'];
    const errors = {};

    for (const field of requiredFields) {
      if (!values[field]) {
        errors[field] = 'Required';
      }
    }

    return errors;
  };

  useEffect(() => {
    setClosing(false);
  }, [context]);

  const handleClose = () => {
    setClosing(true);
    close();
  };

  if (closing) {
    return null;
  }

  return (
    <Dialog
      open={Boolean(context)}
      aria-labelledby="form-dialog-title"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <DialogTitle id="form-dialog-title" disableTypography={true} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h2 className="MuiTypography-root MuiTypography-h6">{initialValues ? 'Edit' : 'Add'} { initialValues ? '' : isChild ? 'Child' : 'Sibling' } Record { !initialValues ? context && context.params.value ? `to ${context.params.value}` : 'to Root' : '' }</h2>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Complete the following details to {initialValues ? 'edit the' : 'create a new'} record
        </DialogContentText>
        <Form
          onSubmit={submit}
          validate={validate}
          initialValues={initialValues}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Grid container alignItems="flex-start" spacing={2}>
                <Grid item xs={12}>              
                  <Field
                    autoFocus
                    margin="dense"
                    name="name"
                    label="Name"
                    type="text"
                    required
                    fullWidth
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={12}>              
                  <Field
                    margin="dense"
                    name="description"
                    label="Description"
                    multiline
                    required
                    fullWidth
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={12}>              
                  <Field
                    name="importance"
                    label="Importance *"
                    required
                    style={{ minWidth: 200 }}
                    component={Select}
                  >
                    <MenuItem value="-">-</MenuItem>
                    <MenuItem value="Core">Core</MenuItem>
                    <MenuItem value="Expected">Expected</MenuItem>
                    <MenuItem value="Important">Important</MenuItem>
                    <MenuItem value="Useful">Useful</MenuItem>
                    <MenuItem value="Esoteric">Esoteric</MenuItem>
                  </Field>
                </Grid>

                <Grid item xs={12}>
                    <Field
                        name="references"
                        label="Reference URLs (line seperated)"
                        multiline
                        margin="dense"
                        fullWidth
                        component={TextField}
                    />
                </Grid>
                <Grid item style={{ marginTop: 16 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitting}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}       
        />
      </DialogContent>
    </Dialog>
  );
}
