import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import InfoIcon from '@material-ui/icons/Info';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ReferencesCellRenderer(props) {
  const [openDialog, setOpenDialog] = useState(false);

  const getUrlCellRenderer = () => {

    function UrlCellRenderer() {}
    UrlCellRenderer.prototype.init = function(params) {
      let url = params.value;

      if (!url.startsWith('http')) {
        url = `http://${url}`;
      }
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = `<a href="${url}" target="_blank">${url}</a>`;
      this.eGui = tempDiv.firstChild;
    };
    UrlCellRenderer.prototype.getGui = function() {
      return this.eGui;
    };

    return UrlCellRenderer;
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const onGridReady = params => {
    params.api.sizeColumnsToFit();
  };

  if (props.node.childrenAfterGroup && props.node.childrenAfterGroup.length > 0) {
    return null;
  }

  return (
    <>
      <InfoIcon color="primary" onClick={handleOpenDialog}/>
      <Dialog
        open={Boolean(openDialog)}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">References</DialogTitle>
        <DialogContent>
          <div
            id="referenceGrid"
            style={{
              height: "400px",
              width: "400px"
            }}
            className="ag-theme-balham"
          >
            <AgGridReact
                columnDefs={ [ { headerName: 'URL', field: 'url', rowDrag: false, lockPosition: true, cellRenderer: "urlCellRenderer" } ] }
                components={ { urlCellRenderer: getUrlCellRenderer() } }
                rowData={props.value}
                onGridReady={onGridReady}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
