import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import superagent from 'superagent';
import { makeStyles } from '@material-ui/core/styles';
import { BASE_API_URL } from '../config';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu'; import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

function TopAppBar({ user, onSignOut, ...props }) {
  const classes = useStyles();

  const [loginMenuEl, setLoginMenuEl] = React.useState(null);
  const [appMenuEl, setAppMenuEl] = React.useState(null);

  const handleAppMenuClick = event => {
    setAppMenuEl(event.currentTarget);
  };

  const handleLoginMenuClick = event => {
    setLoginMenuEl(event.currentTarget);
  };

  const handleAppMenuClose = () => {
    setAppMenuEl(null);
  };

  const handleLoginMenuClose = () => {
    setLoginMenuEl(null);
  };

  const handleSignIn = () => {
    superagent
      .get(`${BASE_API_URL}/api/auth/login`)
      .then(result => {
        const width = 600;
        const height = 800;
        const left = window.screenLeft + window.innerWidth / 2 - width / 2;
        const top = window.screenTop + window.innerHeight / 2 - height / 2;
        window.open(result.body.url, 'auth', `width=${width},height=${height},left=${left},top=${top}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSignOut = () => {
    superagent
      .get(`${BASE_API_URL}/api/auth/logout`)
      .withCredentials()
      .then(() => {
        onSignOut();
        setLoginMenuEl(null);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          {user && user.roles.includes('admin') &&
            <>
              <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={handleAppMenuClick}>
                <MenuIcon />
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={appMenuEl}
                keepMounted
                open={Boolean(appMenuEl)}
                onClose={handleAppMenuClose}
              >
                <MenuItem component={Link} to='/' onClick={handleAppMenuClose}>Self Assessment</MenuItem>
                <MenuItem component={Link} to='/admin' onClick={handleAppMenuClose}>Admin</MenuItem>
                <MenuItem component={Link} to='/admin/users' onClick={handleAppMenuClose}>Users</MenuItem>
              </Menu>
            </>
          }
          <Typography variant="h6" className={classes.title}>
            Tech Self Assessment {user && user.roles.includes('admin') && props.location.pathname.startsWith('/admin') && 'Admin'}
          </Typography>
          {user ? (
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleLoginMenuClick}
                color="inherit"
              >
                <AccountCircle />&nbsp;<span>{user.displayName}</span>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={loginMenuEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(loginMenuEl)}
                onClose={handleLoginMenuClose}
              >
                <MenuItem onClick={handleSignOut}>Logout</MenuItem>
              </Menu>
            </div>
          ) : <Button color="inherit" onClick={handleSignIn}>Login</Button> }
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default withRouter(TopAppBar);
