import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Root from './Root';
import './App.css';

function App(props) {
  return (
    <Router>   
      <div className="App">
        <Root />
      </div>
    </Router>
  );
}

export default App;
