import React, { useState, useCallback, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import { makeStyles } from '@material-ui/core/styles';
import superagent from 'superagent';
import uuid4 from 'uuid/v4';
import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useKeyPress } from '../hooks';
import { BASE_API_URL } from '../config';
import FormDialog from './TechGridFormDialog';
import ReferencesCellRenderer from './ReferencesCellRenderer';
import "ag-grid-enterprise";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  cellWrapText: {
    whiteSpace: 'pre-line !important'
  }
});

export default function AdminTechGrid({ onError }) {
  const [gridApi, setGridApi] = useState(null);
  const [openDragDialog, setOpenDragDialog] = useState(false);
  const [hideDragDialog, setHideDragDialog] = useState(false);
  const [formContext, setFormContext] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const [archive, setArchive] = useState(null);
  const [unarchive, setUnarchive] = useState(null);
  const [drag, setDrag] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const shiftPress = useKeyPress('Shift');
  const classes = useStyles();

  const getSkillCellRenderer = () => {

    function SkillCellRenderer() {}
    SkillCellRenderer.prototype.init = function(params) {
      const tempDiv = document.createElement("div");

      if (params.node && params.node.data && params.node.data.active) {
        tempDiv.innerHTML = `<b>${params.value}</b>`;
      } else {
        tempDiv.innerHTML = params.value;
      }

      this.eGui = tempDiv.firstChild;
    };
    SkillCellRenderer.prototype.getGui = function() {
      return this.eGui;
    };

    return SkillCellRenderer;
  };

  const moveInArray = (arr, fromIndex, toIndex) => {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  };

  const moveToPath = useCallback((newParentPath, movingNode, overNode, updatedRows) => {
    const oldPath = [...movingNode.data.name];
    const fileName = oldPath.pop()

    if (JSON.stringify(oldPath) !== JSON.stringify(newParentPath)) {
      const newChildPath = [...newParentPath.slice(), fileName];
      movingNode.data.name = newChildPath;
      if (movingNode.childrenAfterGroup) {
        movingNode.childrenAfterGroup.forEach(function(childNode) {
          updatedRows = moveToPath([...newParentPath, fileName], childNode, overNode, updatedRows);
        });
      }
    } else {
      const fromIndex = updatedRows.indexOf(movingNode.data);
      const toIndex = updatedRows.indexOf(overNode.data);
      moveInArray(updatedRows, fromIndex, toIndex);
    }
  }, []);

  const isSelectionParentOfTarget = useCallback((selectedNode, targetNode) => {
    const selectedName = selectedNode.data.name.join();
    const targetName = targetNode.data.name.join();

    return targetName.startsWith(selectedName);
  }, []);

  useEffect(() => {
    setLoading(true);
    const url = showArchived ? `${BASE_API_URL}/api/skills/all` : `${BASE_API_URL}/api/skills/`;
    
    superagent
      .get(url)
      .withCredentials()
      .then(response => {
        const data = response.body.results.map(
          row => {
            return {
              ...row,
              name: row.name.map(n => n.value),
              references: row.references.map(
                ref => {
                  return { url: ref };
                }
              )
            };
          }
        );

        setRowData(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        onError(error);
      });
  }, [showArchived, onError]);

  const handleCloseArchiveDialog = () => {
    setArchive(null);
  };

  const handleArchiveDialogResponse = useCallback((yes) => {
    if (archive && yes) {
      const updatedRows = []
      const updatedNodeIds = []

      const archivedName = archive.data.name.join();

      for (const data of rowData) {
        const rowName = data.name.join();

        if (rowName.startsWith(archivedName)) {
          updatedRows.push({...data, active: false});
          updatedNodeIds.push(data.node_id);
        } else {
          updatedRows.push({...data});
        }
      }

      setRowData(updatedRows);

      superagent
        .delete(`${BASE_API_URL}/api/skills`)
        .withCredentials()
        .set('Content-Type', 'application/json')
        .send(JSON.stringify(updatedNodeIds))
        .catch((error) => {
          onError(error);
        });

    }

    setArchive(null);
  }, [archive, rowData, onError]);

  const handleOpenDragDialog = () => {
    setOpenDragDialog(true);
  }

  const handleCloseDragDialog = () => {
    setOpenDragDialog(false);
  };
 
  const handleCloseFormDialog = () => {
    setFormContext(null);
  };
 
  const onGridReady = params => {
    params.api.sizeColumnsToFit();
    setGridApi(params.api);
  };

  const onRowDragEnd = event => {
    if (!event.overNode || (event.node.data && !event.node.data.active) || (event.overNode.data && !event.overNode.data.active)) {
      return;
    }

    setDrag({ overNode: event.overNode, movingNode: event.node });

    if (!hideDragDialog) {
      handleOpenDragDialog();
    } else {
      let reorderParent = true;

      if (shiftPress) {
        reorderParent = false;
      }

      setDrag(currentDrag => ({...currentDrag, reorderParent}));
    }
  }

  const handleDragDialogResponse = (reorderParent) => {
    setDrag(currentDrag => ({...currentDrag, reorderParent}));
    handleCloseDragDialog();
  }

  const handleRowDrag = useCallback(() => {
    if (drag && 'reorderParent' in drag) {
      const invalidMode = isSelectionParentOfTarget(drag.movingNode, drag.overNode) || (drag.movingNode.data.name.slice(-1)[0] === drag.overNode.data.name.slice(-1)[0] && drag.reorderParent) || !drag.movingNode.data.active;
      let newParentPath = drag.overNode.data.name;

      if (drag.overNode.parent) {
        if (drag.overNode.parent.id === 'ROOT_NODE_ID') {
          if (drag.reorderParent) {
            newParentPath = [];
          }
        } else if (drag.overNode.parent.data && drag.reorderParent) {
          newParentPath = drag.overNode.parent.data.name
        }
      }

      if (!invalidMode) {
        const getUpdatedRowData = (node) => {
          let updatedRowData = [];

          if (node.childrenAfterGroup && node.childrenAfterGroup.length > 0) {
            if (node.id !== 'ROOT_NODE_ID') {
              updatedRowData = [...updatedRowData, { node_id: node.id, order: node.rowIndex, name: node.data.name }];
            }

            for (const child of node.childrenAfterGroup) {
                updatedRowData = [...updatedRowData, ...getUpdatedRowData(child)];
            }
          } else {
            return [{ node_id: node.id, order: node.rowIndex, name: node.data.name }];
          }

          return updatedRowData;
        };

        const updatedRows = rowData.slice();
        moveToPath(newParentPath, drag.movingNode, drag.overNode, updatedRows);
        setRowData(updatedRows);
        gridApi.setRowData(updatedRows);
        superagent
          .put(`${BASE_API_URL}/api/skills`)
          .withCredentials()
          .set('Content-Type', 'application/json')
          .send(
            JSON.stringify(getUpdatedRowData(gridApi.getRowNode(drag.movingNode.id).parent))
          )
          .catch((error) => {
            onError(error);
          });
        gridApi.clearFocusedCell();
      }

      setDrag(null);
    }
  }, [gridApi, drag, rowData, isSelectionParentOfTarget, moveToPath, onError]);

  useEffect(() => {
    handleRowDrag();
  }, [handleRowDrag]);

  const handleSubmitFormDialog = useCallback(values => {
    const { data: { name: fullName = [] } = {} } = formContext.params.node || {};
    const name = formContext.isChild ? [...fullName, values.name] : [...fullName.slice(0, fullName.length - 1), values.name];
    const { description, importance } = values;
    const references = values.references ? values.references.split('\n').filter(ref => ref.trim() !== '').join('\n') : '';
    

    if (!('initialValues' in formContext)) {
      const newRow = {name, description, importance, references: references ? references.split('\n') : [], order: rowData.length, node_id: uuid4(), active: true};
      const updatedRows = [...rowData, {...newRow, references: references ? references.split('\n').map(ref => { return { url: ref }; }) : []}];
      setRowData(updatedRows);
      superagent
        .post(`${BASE_API_URL}/api/skills/`)
        .withCredentials()
        .set('Content-Type', 'application/json')
        .send(JSON.stringify([newRow]))
        .catch((error) => {
          onError(error);
        });
    } else {
      const allRows = [];
      const updatedRows = [];

      for (const data of rowData) {
        if (isSelectionParentOfTarget(formContext.params.node, gridApi.getRowNode(data.node_id))) {
          const newName = [];

          for (const nodeName of data.name) {
            if (nodeName === fullName.slice(-1)[0]) {
              newName.push(values.name);
            } else {
              newName.push(nodeName);
            }  
          }

          let row = {...data, name: newName};

          if (row.node_id === formContext.params.node.id) {
            row = {...row, importance, description};
            allRows.push({...row, references: references.split('\n').map(ref => { return { url: ref } })});           
          } else {
            allRows.push(row);
          }

          updatedRows.push({...row, references: references.split('\n')});
        } else {
          allRows.push(data);
          updatedRows.push({...data, references: data.references.map(ref => ref.url)});
        }
      }

      setRowData(allRows);

      if (updatedRows) {
        superagent
          .post(`${BASE_API_URL}/api/skills/`)
          .withCredentials()
          .set('Content-Type', 'application/json')
          .send(JSON.stringify(updatedRows))
          .catch((error) => {
            onError(error);
          });
      }
    }
    handleCloseFormDialog();
  }, [formContext, rowData, onError, gridApi, isSelectionParentOfTarget]);

  const handleUnarchive = useCallback(() => {
    if (unarchive) {
      const newRow = {...unarchive.data, active: true};
      let updatedRows = null;

      for (const [index, data] of rowData.entries()) {
        if (data.node_id === unarchive.id) {
          updatedRows = [...rowData.slice(0, index), newRow, ...rowData.slice(index + 1)];
          break;
        }
      }

      if (updatedRows) {
        setRowData(updatedRows);
        superagent
          .post(`${BASE_API_URL}/api/skills/`)
          .withCredentials()
          .set('Content-Type', 'application/json')
          .send(JSON.stringify([{...newRow, references: newRow.references.map(ref => ref.url)}]))
          .catch((error) => {
            onError(error);
          });
      }
    }

    setUnarchive(null);
  }, [unarchive, rowData, onError]);

  useEffect(() => {
    handleUnarchive();
  }, [handleUnarchive]);

  const getContextMenuItems = params => {
    return [
      {
        name: 'Add child record',
        disabled: params.node && !params.node.data.active,
        action: () => {
          setFormContext({ isChild: true, params });
        },
      },
      {
        name: 'Add sibling record',
        disabled: params.node && !params.node.data.active,
        action: () => {
          setFormContext({ isChild: false, params });
        },
      },
      "separator",
      {
        name: 'Edit record',
        disabled: params.node && !params.node.data.active,
        action: () => {
          const { name, description, importance, references } = params.node.data;
          const initialValues = {
            name: name[name.length - 1],
            description,
            importance,
            references: references.map(ref => ref.url).join('\n')
          };
          setFormContext({ params, initialValues });
        },
      },
      "separator",
      {
        name: params.node && params.node.data.active ? 'Archive' : 'Unarchive',
        action: () => {
          if (params.node && params.node.data.active) {
            setArchive(params.node);
          } else {
            setUnarchive(params.node);
          }
        },
      },
      {
        name: 'Toggle Archived',
        action: () => {
          setShowArchived(currentSetting => !currentSetting)
        },
      },
      "separator",
      "copy",
      "paste"
    ];
  };

  if (loading) {
    return (
      <div className={classes.root}>
        <LinearProgress color="secondary" />
      </div>
    );
  }

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        id="techGrid"
        style={{
          height: "100%",
          width: "100%"
        }}
        className="ag-theme-balham"
      >
        <AgGridReact
          columnDefs={
            [
              { field: "description", autoHeight: true, cellClass: classes.cellWrapText, filter: true },
              { field: "importance", filter: true },
              { field: "references", cellRenderer: "referencesCellRenderer" }
            ]
          }
          rememberGroupStateWhenNewData={true}
          defaultColDef={ { resizable: true } }
          floatingFilter={true}
          components={ { skillCellRenderer: getSkillCellRenderer() } }
          frameworkComponents={ { referencesCellRenderer: ReferencesCellRenderer } }
          context={ { componentParent: this } }
          rowData={rowData}
          treeData={true}
          getRowNodeId={data => data.node_id}
          getContextMenuItems={getContextMenuItems}
          groupDefaultExpanded={-1}
          getDataPath={data => data.name}
          suppressMaxRenderedRowRestriction={true}
          quickFilter={'Search'}
          autoGroupColumnDef={
            {
              rowDrag: true,
              headerName: "Skill",
              width: 500,
              lockPosition: true,
              menuTabs: [],
              cellRendererParams: {
                suppressCount: true,
                innerRenderer: "skillCellRenderer"
              }
            }
          }
          onGridReady={onGridReady}
          onRowDragEnd={onRowDragEnd}
        />
      </div>
      <Dialog
        open={openDragDialog}
        onClose={handleCloseDragDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Drag confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span>
              Are you just reordering the item or making it a child of an existing item?
              If you would prefer hide this dialog in the future and just hold 'Shift' to
              make a child of an existing item, tick the box below.
            </span>
            <span style={{ display: "inline-block", marginLeft: "-10px" }}>
              <Checkbox
                checked={hideDragDialog}
                onChange={() => setHideDragDialog(true)}
                inputProps={{
                  'aria-label': 'primary checkbox',
                }}
              /> Hide this dialog and use 'Shift' instead
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDragDialogResponse(true)} color="primary">
            Reordering
          </Button>
          <Button onClick={() => handleDragDialogResponse(false)} color="primary" autoFocus>
            Making a child
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(archive)}
        onClose={handleCloseArchiveDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Archive confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span>
              Are you sure you want to archive <b>{archive ? archive.key : 'skill' }</b> and all of it's children?
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleArchiveDialogResponse(true)} color="primary">
            Yes
          </Button>
          <Button onClick={() => handleArchiveDialogResponse(false)} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>

      <FormDialog close={handleCloseFormDialog} submit={handleSubmitFormDialog} context={formContext} />
    </div>
  );
}


