import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import superagent from 'superagent';
import LinearProgress from '@material-ui/core/LinearProgress';
import { BASE_API_URL } from '../config';
import ReferencesCellRenderer from './ReferencesCellRenderer';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  cellWrapText: {
    whiteSpace: 'pre-line !important'
  }
});

function TechGrid({ onError, ...props }) {
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();  

  const getSkillCellRenderer = () => {

    function SkillCellRenderer() {}
    SkillCellRenderer.prototype.init = function(params) {
      const tempDiv = document.createElement("div");

      if (params.node && params.node.data && params.node.data.active) {
        tempDiv.innerHTML = `<b>${params.value}</b>`;
      } else {
        tempDiv.innerHTML = params.value;
      }

      this.eGui = tempDiv.firstChild;
    };
    SkillCellRenderer.prototype.getGui = function() {
      return this.eGui;
    };

    return SkillCellRenderer;
  };

  useEffect(() => {
    setLoading(true);
    const query = 'userId' in props.match.params ? { userId: props.match.params.userId } : {};
    
    superagent
      .get(`${BASE_API_URL}/api/skills/`)
      .query(query)
      .withCredentials()
      .then(response => {
        const data = response.body.results.map(
          row => {
            return {
              ...row,
              name: row.name.map(n => n.value),
              competency: row.competencies.length > 0 ? row.competencies.sort((a, b) => new Date(b.achieved) - new Date(a.achieved))[0].level : 'Not touched',
              references: row.references.map(
                ref => {
                  return { url: ref };
                }
              )
            };
          }
        );

        setRowData(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        onError(error);
      });
  }, [onError, props.match.params]);

  const getContextMenuItems = params => {
    return [
      "copy",
      "paste"
    ];
  };

  const onCellValueChanged = (params) => {
    if (params.column.colId === 'competency') {
      const { competency: level, node_id } = params.data;

      superagent
        .post(`${BASE_API_URL}/api/skills/competency`)
        .withCredentials()
        .set('Content-Type', 'application/json')
        .send(JSON.stringify({ node_id, level }))
        .catch((error) => {
          onError(error);
        });
    }
  };

  const onGridReady = params => {
    params.api.sizeColumnsToFit();
  };

  if (loading) {
    return (
      <div className={classes.root}>
        <LinearProgress color="secondary" />
      </div>
    );
  }

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        id="techGrid"
        style={{
          height: "100%",
          width: "100%"
        }}
        className="ag-theme-balham"
      >
        <AgGridReact
          columnDefs={
            [              
              {
                field: "description",
                autoHeight: true,
                cellClass: classes.cellWrapText,
                filter: 'agTextColumnFilter',
              },
              {
                field: "importance",
                filter: true
              },
              {
                field: "competency",
                headerName: "My Competency",
                filter: true,
                editable: (params) => { return !params.node.childrenAfterGroup || params.node.childrenAfterGroup.length <= 0; },
                cellEditor: "agRichSelectCellEditor",
                cellEditorParams: {
                  values: [
                    "Not touched",
                    "Read documentation",
                    "Limited use",
                    "Extensive use",
                    "Can teach others"
                  ]
                },
                valueGetter: params => params && params.node && (!params.node.childrenAfterGroup || params.node.childrenAfterGroup.length <= 0) ? params.data.competency : ''
              },
              {
                field: "references",
                cellRenderer: "referencesCellRenderer"
              }
            ]
          }
          rememberGroupStateWhenNewData={true}
          defaultColDef={ { resizable: true } }
          floatingFilter={true}
          components={ { skillCellRenderer: getSkillCellRenderer() } }
          frameworkComponents={ { referencesCellRenderer: ReferencesCellRenderer } }
          rowData={rowData}
          treeData={true}
          getRowNodeId={data => data.node_id}
          getContextMenuItems={getContextMenuItems}
          groupDefaultExpanded={-1}
          getDataPath={data => data.name}
          suppressMaxRenderedRowRestriction={true}
          autoGroupColumnDef={
            {
              rowDrag: false,
              headerName: "Skill",
              width: 500,
              lockPosition: true,
              menuTabs: [],
              cellRendererParams: {
                suppressCount: true,
                innerRenderer: "skillCellRenderer"
              },
              filter: 'agTextColumnFilter',
            }
          }
          onCellValueChanged={onCellValueChanged}
          onGridReady={onGridReady}
        />
      </div>
    </div>
  );
}

export default withRouter(TechGrid);
