import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import { makeStyles } from '@material-ui/core/styles';
import superagent from 'superagent';
import LinearProgress from '@material-ui/core/LinearProgress';
import { BASE_API_URL } from '../config';
import "ag-grid-enterprise";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  cellWrapText: {
    whiteSpace: 'pre-line !important'
  }
});

export default function UserGrid({ onError }) {
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  const getEmailCellRenderer = () => {

    function EmailCellRenderer() {}
    EmailCellRenderer.prototype.init = function(params) {
      const tempDiv = document.createElement("div");

      if (params.node && params.node.data && params.node.data.id) {
        tempDiv.innerHTML = `<a href="/admin/users/${params.node.data.id}" target="_blank">${params.value}</a>`;
      } else {
        tempDiv.innerHTML = params.value;
      }

      this.eGui = tempDiv.firstChild;
    };
    EmailCellRenderer.prototype.getGui = function() {
      return this.eGui;
    };

    return EmailCellRenderer;
  };

  useEffect(() => {
    setLoading(true);
    
    superagent
      .get(`${BASE_API_URL}/api/users/`)
      .withCredentials()
      .then(response => {
        setRowData(response.body);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        onError(error);
      });
  }, [onError]);

  const getContextMenuItems = params => {
    return [
      "copy",
      "paste"
    ];
  };

  const onGridReady = params => {
    params.api.sizeColumnsToFit();
  };

  if (loading) {
    return (
      <div className={classes.root}>
        <LinearProgress color="secondary" />
      </div>
    );
  }

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        id="userGrid"
        style={{
          height: "100%",
          width: "100%"
        }}
        className="ag-theme-balham"
      >
        <AgGridReact
          columnDefs={
            [
              {
                field: "email",
                cellRenderer: "emailCellRenderer",
                width: 400
              },
              {
                field: "last_login",
                valueFormatter: params => (new Date(params.value)).toLocaleDateString("en-AU")
              },
            ]
          }
          components={ { emailCellRenderer: getEmailCellRenderer() } }
          defaultColDef={ { resizable: true } }
          rowData={rowData}
          getContextMenuItems={getContextMenuItems}
          onGridReady={onGridReady}
        />
      </div>
    </div>
  );
}


