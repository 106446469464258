import React, { useEffect, useState } from 'react';
import superagent from 'superagent';
import { Switch, Route, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import { BASE_API_URL } from '../config';
import TopAppBar from '../components/TopAppBar';
import TechGrid from '../components/TechGrid';
import UserTechGrid from '../components/UserTechGrid';
import UserGrid from '../components/UserGrid';
import AdminTechGrid from '../components/AdminTechGrid';

const useStyles = makeStyles(theme => ({
  logo: {
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bold'
  }
}));

function Root(props) {
  const [user, setUser] = useState(null);
  const classes = useStyles();

  const handleSignOut = () => {
    setUser(null);
    props.history.push('/');
  };

  const handleAuthResponse = event => {
    if (typeof event.data === "string") {
      const userDetails = JSON.parse(event.data);
      if (userDetails.hasOwnProperty("displayName") && userDetails.hasOwnProperty("roles")) {
        setUser(userDetails);
      }
    }
  };

  const handleError = error => {
    if (error.response.status === 401) {
      alert('Your session has expired.');
      setUser(null);
    } else if (error.response.status === 403) {
      alert('You don\'t have permission.');
    } else {
      alert('An error occurred, please check the console for more details.');
    }

    console.log(error);
  };

  useEffect(() => {
    window.addEventListener('message', handleAuthResponse);
    superagent
      .get(`${BASE_API_URL}/api/auth/details`)
      .withCredentials()
      .then(result => {
        const details = result.body;

        if (details.displayName && details.roles) {
          setUser(details);
        }
      })

    return () => {
      window.removeEventListener('message', handleAuthResponse);
    };
  }, []);

  return (
    <>   
      <TopAppBar user={user} onSignOut={handleSignOut} />
      {!user &&
        <div>
          <div className={classes.logo} style={{ fontSize: '25em' }}>
            <ImportContactsIcon color="primary" fontSize="inherit"/>
          </div>
          <div className={classes.logo} style={{ fontSize: '3em' }}>
            <span>Tech Self Assessment</span>
          </div>
        </div>
      }
      <Switch>
        {user && user.roles.includes('admin') && <Route path='/admin/users/:userId' render={() => <UserTechGrid onError={handleError} />} />}
        {user && user.roles.includes('admin') && <Route path='/admin/users' render={() => <UserGrid onError={handleError} />} />}
        {user && user.roles.includes('admin') && <Route path='/admin' render={() => <AdminTechGrid onError={handleError} />} />}
        {user && <Route path='/' render={() => <TechGrid onError={handleError} />} />}
      </Switch>
    </>
  );
}

export default withRouter(Root);
